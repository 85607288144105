import { mapGetters } from 'vuex';

export default {
  name: 'MoreDealCollapse',
  props: {
    moreItems: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      utmSource: this.$route.query.utm_source || '',
      locationInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    fcAgentModeParam() {
      return (this.isFcAgentMarketerMode) ? '&fc-agent-mode' : '';
    },
    items() {
      const { moreItems } = this;
      if (!moreItems || moreItems.length === 0) return [];
      // const restrictedItems = moreItems.filter((dl) => dl.nights > 2);
      // const nights2Deal = moreItems.find((dl) => dl.nights === 2);
      // if (nights2Deal) restrictedItems.push(nights2Deal);
      // return [...restrictedItems.sort((a, b) => new Date(a.departureDate) - new Date(b.departureDate))].slice(0, 4);
      // return [...moreItems.sort((a, b) => new Date(a.departureDate) - new Date(b.departureDate))].slice(0, 4);
      return [...moreItems.sort((a, b) => {
        const priceA = a.packageType === 'F' ? a.discountedPriceFO.priceByAge[0].price : a.discountedPrice.price_average,
          priceB = b.packageType === 'F' ? b.discountedPriceFO.priceByAge[0].price : b.discountedPrice.price_average;
        let result = 0;
        result = priceA < priceB ? -1 : 1;
        return result;
      })].slice(0, 4);
    },
  },
  mounted() {
    document.querySelector('.st-content').addEventListener('scroll', () => {
      if (this.$refs.dropdown) this.$refs.dropdown.hide();
    });
    this.locationInfo = { location: this.$refs.position?.offsetParent?.offsetParent?.offsetTop, categoryId: this.items[0].categoryId };
  },
  methods: {
    changeDateFormat(str) {
      return str.split('-').reverse().join('.');
    },
    getLink(elm) {
      if (this.isLanding) {
        return elm.toOldSite ? `${elm.productPageUrlInOldSite}&utm_source=${this.landingInfo.utm_source}` : `${elm.selectedPackageUrl}&utm_source=${this.landingInfo.utm_source}`;
      } else {
        return elm.toOldSite ? `${elm.productPageUrlInOldSite}&utm_source=${this.utmSource}${this.fcAgentModeParam}` : `${elm.selectedPackageUrl}&utm_source=${this.utmSource}${this.fcAgentModeParam}`;
      }
    },
    recordLocation() {
      this.$store.commit('SET_DEAL_LOCATION', this.locationInfo);
    },
  },
};
